import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import FormItemList, {
  Props as FormItemListProps,
} from "../components/molecules/FormItemList";
import Form from "../components/molecules/Form";
import SubmitButton from "../components/atoms/SubmitButton";

import { toastSuccess, toastError } from "../utils/appearError";
import { Labels, formRules } from "../utils/forms/creasUserRegistration";
import { payeeApi, creatorsApi, businessInvoiceApi } from "../utils/ajax";
import { useCreasUserFirstRegistrationForm } from "../hooks/CreasUserFirstRegistrationForm";

const CreasUserFirstRegistration: React.FC = () => {
  const { register, errors, handleSubmit } = useForm<Labels>();
  const history = useHistory();
  const {
    isLoading,
    payment,
    invoice,
    paymentMethod,
    paymentFormItemProps,
    creatorFormItemProps,
    invoiceFormItemProps,
  } = useCreasUserFirstRegistrationForm();

  const profileFormItems: FormItemListProps<Labels> = {
    rules: formRules,
    formProps: creatorFormItemProps,
    errors,
    register,
  };

  const paymentFormItems: FormItemListProps<Labels> = {
    rules: formRules,
    formProps: paymentFormItemProps,
    errors,
    register,
  };

  const invoiceFormItems: FormItemListProps<Labels> = {
    rules: formRules,
    formProps: invoiceFormItemProps,
    errors,
    register,
  };

  const onSubmitFunc = async (data: Labels) => {
    const creatorPayloadArray: Array<keyof Labels> = [
      "phoneNumber",
      "postcode",
      "address",
      "parentLastName",
      "parentFirstName",
      "parentPhoneNumber",
      "parentRelationship",
      "firstName",
      "lastName",
      "firstNameKana",
      "lastNameKana",
      "birthdate",
      "gender",
    ];

    const paymentPayload = {
      bankName: data.bankName || payment?.bankName,
      bankCode: data.bankCode || payment?.bankCode,
      branchName: data.branchName || payment?.branchName,
      branchCode: data.branchCode || payment?.branchCode,
      accountType: data.accountType || payment?.accountType,
      accountNo: data.accountNo || payment?.accountNo,
      accountName: data.accountName || payment?.accountName,
    };

    const invoicePayload = {
      businessInvoiceNumber:
        data.businessInvoiceNumber || invoice?.businessInvoiceNumber,
    };

    const creatorPayload: Partial<Labels> = data;

    (Object.keys(data) as (keyof Labels)[]).forEach((key: keyof Labels) => {
      if (!creatorPayloadArray.includes(key)) delete creatorPayload[key];
    });

    try {
      await creatorsApi.patchCreators(creatorPayload);
      if (paymentMethod !== "other") await payeeApi.updatePayee(paymentPayload);
      await businessInvoiceApi.updateBusinessInvoice(invoicePayload);

      toastSuccess("個人情報を変更しました");
      history.push("/");
    } catch {
      toastError("通信エラーにより変更できませんでした");
    }
  };

  if (isLoading) return <div data-testid="dummy"></div>;

  return (
    <Content>
      <Form onSubmit={handleSubmit(onSubmitFunc)} align="center">
        <FormList>
          {profileFormItems.formProps.length > 0 && (
            <FormItem>
              <FormDetailHeader>個人情報</FormDetailHeader>
              <FormItemList<Labels> {...profileFormItems}></FormItemList>
            </FormItem>
          )}
          {paymentFormItems.formProps.length > 0 && (
            <FormItem>
              <FormDetailHeader>口座情報</FormDetailHeader>
              <FormItemList<Labels> {...paymentFormItems}></FormItemList>
            </FormItem>
          )}
          {invoiceFormItems.formProps.length > 0 && (
            <FormItem>
              <FormItemList<Labels> {...invoiceFormItems}></FormItemList>
            </FormItem>
          )}
        </FormList>
        <SubmitButton
          name="commit"
          value="保存"
          backgroundColor="#0074AE"
          dataDisableValue="保存"
        />
      </Form>
    </Content>
  );
};

export default CreasUserFirstRegistration;

const Content = styled.div`
  padding: 1.8rem;
`;

const FormDetailHeader = styled.h2`
  width: 100%;
  margin-bottom: 2.4rem;
  text-align: left;
`;

const FormList = styled.ul`
  margin-top: 1.2rem;
  margin-bottom: -0.8rem;
  max-width: 60rem;
  width: 100%;
  list-style: none;
  padding: 0;
`;

const FormItem = styled.li`
  margin-top: 4.8rem;

  @media (max-width: 896px) {
    margin-top: 0;
  }

  width: 100%;
`;
