import { useState, useEffect } from "react";
import { creatorsApi, payeeApi } from "../utils/ajax";
import { CREAS, LMND } from "../constants";

interface CreasUserRegistrationCheck {
  isLoading: boolean;
  isProfileFilled: boolean;
}

// もともとcreasだけだったが、ライブ配信の人も個人情報の登録が必須になった
export function useCreasUserRegistrationCheck(
  path?: string
): CreasUserRegistrationCheck {
  const [status, setStatus] = useState<CreasUserRegistrationCheck>({
    isLoading: true,
    isProfileFilled: false,
  });

  useEffect(() => {
    Promise.all([payeeApi.getPayee(), creatorsApi.getCreators()])
      .then((value) => {
        const payment = value[0];
        const creator = value[1];
        if (
          !creator ||
          (!creator?.availableServices?.includes(CREAS) &&
            !creator?.availableServices?.includes(LMND) &&
            !creator?.isLiveStreamingCreator)
        )
          return setStatus({ isLoading: false, isProfileFilled: true });
        if (
          !!creator.phoneNumber &&
          !!creator.firstName &&
          !!creator.lastName &&
          !!creator.firstNameKana &&
          !!creator.lastNameKana &&
          !!creator.birthdate &&
          !!creator.gender &&
          !!creator.postcode &&
          !!creator.address &&
          (!creator.birthdate ||
            (creator.age && creator.age > 19) ||
            (!!creator.parentFirstName &&
              !!creator.parentLastName &&
              !!creator.parentRelationship &&
              !!creator.parentPhoneNumber)) &&
          payment &&
          (payment.paymentMethod === "other" ||
            (payment.zenginBank &&
              !!payment.zenginBank.bankName &&
              !!payment.zenginBank.bankCode &&
              !!payment.zenginBank.branchName &&
              !!payment.zenginBank.branchCode &&
              !!payment.zenginBank.accountType &&
              !!payment.zenginBank.accountNo &&
              !!payment.zenginBank.accountName))
        )
          setStatus({ isLoading: false, isProfileFilled: true });
        else setStatus({ isLoading: false, isProfileFilled: false });
      })
      .catch((_) => setStatus({ isLoading: false, isProfileFilled: false }));
  }, [path]);
  return status;
}
